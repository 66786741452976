.pagination ul {
  align-items: stretch;
}
.pagination ul li {
  background-color: #f1f4fb;
  display: flex;
  align-items: center;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  opacity: 1;
  min-width: 39px;
  height: 44px;
}

.pagination ul li button {
  margin: 0;

  font-family: karla;
  min-width: 39px;
  height: 44px;
  font-size: 16px;
}
.pagination ul li:first-child {
  margin-right: 15px;
  border-radius: 6px;
  min-width: 39px;
  height: 44px;
}
.pagination ul li:last-child {
  margin-left: 15px;
  border-radius: 6px;
  min-width: 39px;
  height: 44px;
}
/* .pagination ul li:active {
  background-color: #1bb99a;
} */
.pagination ul li:nth-child(2) {
  padding-left: 34px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination ul li:nth-last-child(2) {
  padding-right: 34px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination .MuiPagination-ul {
  display: flex;
  justify-content: flex-end;
  /* justify-content: center; */
  margin-top: 20px !important;
}
