body {
  font-family: "Open Sans", sans-serif;
}

div#layout {
  text-align: center;
}

div#container {
  width: 100%;
  height: 500px;
  margin: auto;
  background-color: #00796b;
}

.axis path,
.axis line {
  stroke: #fff;
  stroke-width: 2;
}
.axis text {
  color: #fff;
}

line#limit {
  stroke: #fed966;
  stroke-width: 3;
  stroke-dasharray: 3 6;
}

.grid path {
  stroke-width: 0;
}

.grid .tick line {
  stroke: #9faaae;
  stroke-opacity: 0.3;
}

text.divergence {
  font-size: 12px;
  fill: #2f4a6d;
}

text.value {
  font-size: 12px;
}

text.title {
  font-size: 22px;
  font-weight: 600;
}

text.label {
  font-size: 12px;
  font-weight: 400;
}

text.source {
  font-size: 10px;
}
