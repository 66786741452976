.arcText {
  font-size: 0.75em;
  fill: #fff;
}
.circletext {
  font-size: 1.5em;
}
.circlecount {
  font-size: 1.5em;

  fill: #00796b;
  padding: 25px;
}
