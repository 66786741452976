body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.DrawerMain {
  overflow-y: scroll;
}
.DrawerMain::-webkit-scrollbar {
  display: none;
}

.pagination ul {
  align-items: stretch;
}
.pagination ul li {
  background-color: #f1f4fb;
  display: flex;
  align-items: center;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  opacity: 1;
  min-width: 39px;
  height: 44px;
}

.pagination ul li button {
  margin: 0;

  font-family: karla;
  min-width: 39px;
  height: 44px;
  font-size: 16px;
}
.pagination ul li:first-child {
  margin-right: 15px;
  border-radius: 6px;
  min-width: 39px;
  height: 44px;
}
.pagination ul li:last-child {
  margin-left: 15px;
  border-radius: 6px;
  min-width: 39px;
  height: 44px;
}
/* .pagination ul li:active {
  background-color: #1bb99a;
} */
.pagination ul li:nth-child(2) {
  padding-left: 34px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination ul li:nth-last-child(2) {
  padding-right: 34px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination .MuiPagination-ul {
  display: flex;
  justify-content: flex-end;
  /* justify-content: center; */
  margin-top: 20px !important;
}

.DrawerMain {
  overflow-y: scroll;
}
.DrawerMain::-webkit-scrollbar {
  display: none;
}

.grid path {
  stroke-width: 0;
}

.grid .tick line {
  stroke: #e7ebf4;
  stroke-opacity: 0.3;
}


.arcText {
  font-size: 0.75em;
  fill: #fff;
}
.circletext {
  font-size: 1.5em;
}
.circlecount {
  font-size: 1.5em;

  fill: #00796b;
  padding: 25px;
}

body {
  font-family: "Open Sans", sans-serif;
}

div#layout {
  text-align: center;
}

div#container {
  width: 100%;
  height: 500px;
  margin: auto;
  background-color: #00796b;
}

.axis path,
.axis line {
  stroke: #fff;
  stroke-width: 2;
}
.axis text {
  color: #fff;
}

line#limit {
  stroke: #fed966;
  stroke-width: 3;
  stroke-dasharray: 3 6;
}

.grid path {
  stroke-width: 0;
}

.grid .tick line {
  stroke: #9faaae;
  stroke-opacity: 0.3;
}

text.divergence {
  font-size: 12px;
  fill: #2f4a6d;
}

text.value {
  font-size: 12px;
}

text.title {
  font-size: 22px;
  font-weight: 600;
}

text.label {
  font-size: 12px;
  font-weight: 400;
}

text.source {
  font-size: 10px;
}

.Tooltip {
  border: 1px solid #757575;
  box-shadow: none;
  display: inline-block;
  position: absolute;
  padding: 8px;
  text-align: left;
  font-size: 12px;
  background: #f5f5f5;
  pointer-events: none;
  white-space: nowrap;
}

.DrawerMain {
  overflow-y: scroll;
}
.DrawerMain::-webkit-scrollbar {
  display: none;
}

.DrawerMain {
  overflow-y: scroll;
}
.DrawerMain::-webkit-scrollbar {
  display: none;
}

.CameraGrid {
  overflow-y: auto;
}
/* width */
.CameraGrid::-webkit-scrollbar {
  width: 16px;
  height: 8px;
}

/* Track */
.CameraGrid::-webkit-scrollbar-track {
  /* border-radius: 5px; */
  background: #f1f4fb 0% 0% no-repeat padding-box;
  opacity: 1;
}
/* Handle */
.CameraGrid::-webkit-scrollbar-thumb {
  background: #d2d6e1 0% 0% no-repeat padding-box;
  width: 16px;
  height: 64px;
  border-radius: 5px;
}

/* Handle on hover */
.CameraGrid::-webkit-scrollbar-thumb:hover {
  background: #d2d6e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.CameraGrid::-webkit-scrollbar-thumb:active {
  background-color: #d2d6e1;
}
/* Buttons */
.CameraGrid::-webkit-scrollbar-button:single-button {
  background-color: #f1f4fb;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
}
/* Up */
.CameraGrid::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
  border-radius: 5px 5px 0px 0px;
}
.CameraGrid::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.CameraGrid::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
  border-radius: 0px 0px 5px 5px;
}

.CameraGrid::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.CameraGrid {
  overflow-y: auto;
}
/* width */
.CameraGrid::-webkit-scrollbar {
  width: 16px;
  height: 8px;
}

/* Track */
.CameraGrid::-webkit-scrollbar-track {
  /* border-radius: 5px; */
  background: #f1f4fb 0% 0% no-repeat padding-box;
  opacity: 1;
}
/* Handle */
.CameraGrid::-webkit-scrollbar-thumb {
  background: #d2d6e1 0% 0% no-repeat padding-box;
  width: 16px;
  height: 64px;
  border-radius: 5px;
}

/* Handle on hover */
.CameraGrid::-webkit-scrollbar-thumb:hover {
  background: #d2d6e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.CameraGrid::-webkit-scrollbar-thumb:active {
  background-color: #d2d6e1;
}
/* Buttons */
.CameraGrid::-webkit-scrollbar-button:single-button {
  background-color: #f1f4fb;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
}
/* Up */
.CameraGrid::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
  border-radius: 5px 5px 0px 0px;
}
.CameraGrid::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.CameraGrid::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
  border-radius: 0px 0px 5px 5px;
}

.CameraGrid::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

