.CameraGrid {
  overflow-y: auto;
}
/* width */
.CameraGrid::-webkit-scrollbar {
  width: 16px;
  height: 8px;
}

/* Track */
.CameraGrid::-webkit-scrollbar-track {
  /* border-radius: 5px; */
  background: #f1f4fb 0% 0% no-repeat padding-box;
  opacity: 1;
}
/* Handle */
.CameraGrid::-webkit-scrollbar-thumb {
  background: #d2d6e1 0% 0% no-repeat padding-box;
  width: 16px;
  height: 64px;
  border-radius: 5px;
}

/* Handle on hover */
.CameraGrid::-webkit-scrollbar-thumb:hover {
  background: #d2d6e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.CameraGrid::-webkit-scrollbar-thumb:active {
  background-color: #d2d6e1;
}
/* Buttons */
.CameraGrid::-webkit-scrollbar-button:single-button {
  background-color: #f1f4fb;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
}
/* Up */
.CameraGrid::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
  border-radius: 5px 5px 0px 0px;
}
.CameraGrid::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.CameraGrid::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
  border-radius: 0px 0px 5px 5px;
}

.CameraGrid::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}
